import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { Learner } from '../learner/learner';
import { Navigation } from '../navigation/navigation';
import { theme } from '../theme';

/* eslint-disable-next-line */
export interface DashboardProps {
  children?: React.ReactElement;
}

const StyledDashboard = styled.div`
  background-color: ${theme.greyLightest};
  box-sizing: border-box;
  position: relative;

  > .logo {
    img.desktop {
      position: relative;
      left: -0.4rem;
      top: -0.4rem;
      width: 200px;
    }

    img.mobile {
      display: none;
    }
  }

  > .help {
    align-items: center;
    background-color: ${theme.blueDarkest};
    border: 1px solid ${theme.blueDarkest};
    border-radius: 3rem;
    box-sizing: border-box;
    color: white;
    display: flex;
    font-size: 2.4rem;
    grid-area: learner;
    justify-content: center;
    line-height: 2.4rem;
    text-align: center;
    text-decoration: none;

    span {
      position: relative;
      top: -0.2rem;
    }
  }

  > header {
    background-color: white;
  }

  > nav {
    background-color: white;
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    position: fixed;
    width: 100%;
  }

  @media only screen and ${theme.largeDevice} {
    align-items: center;

    > .help {
      visibility: hidden;
    }

    > .logo {
      grid-area: logo;
      height: 5.3rem;
    }
  }

  @media only screen and ${theme.extraLargeDevice} {
    box-shadow: ${theme.boxShadow};
    display: grid;
    grid-template-areas:
      "header header"
      "navigation content";
    grid-template-columns: ${theme.gutterDouble} auto;

    > header {
      grid-area: header;
      padding-right: ${theme.gutter};
    }

    > main {
      grid-area: content;
      padding-right: ${theme.gutter};
    }

    > nav {
      align-self: stretch;
      grid-area: navigation;
      position: static;
    }
  }

  @media only screen and ${theme.notLargeDevice} {
    padding: 5rem 0 6.8rem;

    > .help {
      height: 3rem;
      right: 0.8rem;
      position: fixed;
      top: 0.8rem;
      width: 3rem;
    }

    > .logo {
      left: 0.8rem;
      position: fixed;
      top: 0.4rem;

      img.desktop {
        display: none;
      }

      img.mobile {
        display: block;
        width: 3.9rem;
        height: 3.8rem;
      }
    }
  }
`;

// todo: Is this used anywhere?
export const Dashboard = (props: DashboardProps) => {
  const { help } = useEnvironment();
  return (
    <StyledDashboard >
      <main>{props.children}</main>
      <nav><Navigation /></nav>
      <header>
        <Learner />
        <a className="help" href={help} target="_blank" title="Need Help? Click here"><span>?</span></a>
        <div className="logo"><Link to="/"><img className="desktop" src="..assets/lawcpd_logo.svg" /><img className="mobile" src="..assets/lawcpd_icon.svg" /></Link></div>
      </header>
    </StyledDashboard>
  );
};

export default Dashboard;
