import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useEnvironment } from '@lawcpd/learner/shared/provider';
import { Learner } from './learner/learner';
import { Navigation } from './navigation/navigation';
import { theme } from './theme';

/* eslint-disable-next-line */
export interface LearnerAppProps {
  children: React.ReactElement;
}

const StyledLearnerApp = styled.div`
  box-sizing: border-box;
  position: relative;

  > .logo {
    grid-area: logo;

    img.desktop {
      position: relative;
      left: -0.4rem;
      top: -0.4rem;
      width: 200px;
    }

    img.mobile {
      display: none;
    }
  }

  > .help {
    align-items: center;
    background-color: ${theme.blueDarkest};
    border: 1px solid ${theme.blueDarkest};
    border-radius: 3rem;
    box-sizing: border-box;
    color: white;
    display: flex;
    font-size: 2.4rem;
    grid-area: learner;
    justify-content: center;
    line-height: 2.4rem;
    text-align: center;
    text-decoration: none;

    span {
      position: relative;
      top: -0.2rem;
    }
  }

  > .sidebar {
    background-color: white;
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    position: fixed;
    width: 100%;
  }

  @media only screen and ${theme.largeDevice} {
    align-items: center;
    display: grid;
    grid-gap: 2.4rem;
    grid-template-areas:
      "logo welcome learner"
      "content content13 content12"
      "content21 content23 content22";
    grid-template-columns: 0fr 1fr 48rem;
    grid-template-rows: 0fr 1fr max-content;
    padding: 0.8rem;

    > .help {
      visibility: hidden;
    }

    > .logo {
      height: 5.3rem;
    }
  }

  @media only screen and ${theme.extraLargeDevice} {
    grid-template-areas:
      "logo welcome learner"
      "sidebar content content12"
      "sidebar content21 content22";
    padding-right: ${theme.gutter};

    > .help {
      height: 6rem;
      position: absolute;
      right: 1.9rem;
      top: 0.8rem;
      width: 6rem;
      visibility: visible;
    }

    > .sidebar {
      align-self: stretch;
      grid-area: sidebar;
      position: static;
    }
  }

  @media only screen and ${theme.notLargeDevice} {
    padding: 5rem 0 6.8rem;

    > .help {
      height: 3rem;
      right: 0.8rem;
      position: fixed;
      top: 0.8rem;
      width: 3rem;
    }

    > .logo {
      left: 0.8rem;
      position: fixed;
      top: 0.4rem;

      img.desktop {
        display: none;
      }

      img.mobile {
        display: block;
        width: 3.9rem;
        height: 3.8rem;
      }
    }
  }
`;

// todo: This appears to only be used in local tests, may be because we can't import from learner app.
// todo: If based on Mycpd component, then it is out of date.
export const LearnerApp = (props: LearnerAppProps) => {
  const { help } = useEnvironment();
  return (
    <StyledLearnerApp>
      {props.children}
      <div className="sidebar"><Navigation /></div>
      <Learner />
      <a className="help" href={help} target="_blank" title="Need Help? Click here"><span>?</span></a>
      <div className="logo"><Link to="/"><img className="desktop" src="/assets/lawcpd_logo.svg" /><img className="mobile" src="/assets/lawcpd_icon.svg" /></Link></div>
    </StyledLearnerApp>
  );
};

export default LearnerApp;
