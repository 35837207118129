import React from 'react';
import type { Meta } from '@storybook/react';
import { getDefaultFeatureFlags, FeatureFlags } from '@lawcpd/feature-flags';
import { FeatureFlagsContext } from './feature-flags';

const defaults = getDefaultFeatureFlags();

// Feature Flag Provider Decorator
export const StorybookFeatureFlagsProvider = (Story, context) => {
  const {
    ADD_EXTERNAL_CPD_11361,
    API_LEARNER_POST_CREATES,
    CASE_9228,
    COMPLETION_SUMMARY_CASE_9072,
    COURSE_COMPLETION_AI_FEEDBACK_EMAIL_FOR_COURSE_607_CASE_11835,
    COURSE_COMPLETION_AI_FEEDBACK_EMAIL_FOR_COURSE_638_CASE_11835,
    COURSE_COMPLETION_EMAIL_WITH_COPYRIGHT_CASE_11836,
    DISPLAY_EXTERNAL_CPD_11361,
    DISPLAY_AI_ROLEPLAY_11660,
    DISPLAY_UPDATED_CHATBOT_11328,
    RECOMMENDATIONS,
    REVIEWABLE_CASE_10297,
    SPLIT_OUT_FEATURE_API_10126,
    SPLIT_OUT_MAGENTO_API_10125,
    SPLIT_OUT_SUPPORT_API_10126,
    SPLIT_OUT_THIRD_PARTY_API_10125,
    SYNC_MAGENTO_COURSE_V2_CASE_11076,
    USE_BACKUP_ACCOUNT_10640,
    USE_COURSE_CHAT_AUTH_10705,
    USE_COURSE_IFRAMES_CASE_9404,
    USE_DRUPAL,
    USE_GENAI_COURSE_COMPLETION_EMAIL_10838,
    USE_GENAI_COURSE_COMPLETION_EMAIL_CASE_11559_COURSE_337,
    USE_GENAI_COURSE_COMPLETION_EMAIL_CASE_11559_COURSE_539,
    USE_GENAI_COURSE_COMPLETION_EMAIL_CASE_11559_COURSE_646,
    USE_GENAI_COURSE_COMPLETION_EMAIL_CASE_11559_COURSE_682,
    USE_LEARNER_DASHBOARD,
    USE_MAILCHIMP_CASE_5763,
    USE_MAILCHIMP_CASE_9296,
    USE_MAILCHIMP_CPD_YEAR_CASE_10884,
    USE_UPLOADED_REPORTS_10765,
    USE_ADDITIONAL_MATERIALS_11602,
    featureFlagError, featureFlagLoading
  } = context.args;

  return (
    <FeatureFlagsContext.Provider
      value={{
        error: featureFlagError,
        featureFlags: {
          ADD_EXTERNAL_CPD_11361,
          API_LEARNER_POST_CREATES,
          CASE_9228,
          COMPLETION_SUMMARY_CASE_9072,
          COURSE_COMPLETION_AI_FEEDBACK_EMAIL_FOR_COURSE_607_CASE_11835,
          COURSE_COMPLETION_AI_FEEDBACK_EMAIL_FOR_COURSE_638_CASE_11835,
          COURSE_COMPLETION_EMAIL_WITH_COPYRIGHT_CASE_11836,
          DISPLAY_EXTERNAL_CPD_11361,
          DISPLAY_AI_ROLEPLAY_11660,
          DISPLAY_UPDATED_CHATBOT_11328,
          RECOMMENDATIONS,
          REVIEWABLE_CASE_10297,
          SPLIT_OUT_FEATURE_API_10126,
          SPLIT_OUT_SUPPORT_API_10126,
          SPLIT_OUT_THIRD_PARTY_API_10125,
          SPLIT_OUT_MAGENTO_API_10125,
          SYNC_MAGENTO_COURSE_V2_CASE_11076,
          USE_BACKUP_ACCOUNT_10640,
          USE_COURSE_CHAT_AUTH_10705,
          USE_COURSE_IFRAMES_CASE_9404,
          USE_DRUPAL,
          USE_GENAI_COURSE_COMPLETION_EMAIL_10838,
          USE_GENAI_COURSE_COMPLETION_EMAIL_CASE_11559_COURSE_337,
          USE_GENAI_COURSE_COMPLETION_EMAIL_CASE_11559_COURSE_539,
          USE_GENAI_COURSE_COMPLETION_EMAIL_CASE_11559_COURSE_646,
          USE_GENAI_COURSE_COMPLETION_EMAIL_CASE_11559_COURSE_682,
          USE_LEARNER_DASHBOARD,
          USE_MAILCHIMP_CASE_5763,
          USE_MAILCHIMP_CASE_9296,
          USE_MAILCHIMP_CPD_YEAR_CASE_10884,
          USE_UPLOADED_REPORTS_10765,
          USE_ADDITIONAL_MATERIALS_11602
        },
        loading: featureFlagLoading,
      }}
    >
      <Story />
    </FeatureFlagsContext.Provider>
  );
};

type FeatureFlagArgTypes = {
  name: string;
  control: "boolean",
  table: {
    category: "Feature Flags";
    subcategory?: string;
  }
};

export interface IStoryFeatureFlagsContext extends FeatureFlags {
  featureFlagError: boolean,
  featureFlagLoading: boolean
}

export const StorybookFeatureFlagArgTypes = Object.keys(defaults).reduce<Record<string, FeatureFlagArgTypes>>((argTypes, flag) => {
  const argType: FeatureFlagArgTypes = {
    name: flag,
    control: "boolean",
    table: {
      category: "Feature Flags"
    }
  };

  argTypes[flag] = argType;

  return argTypes;
}, {
  featureFlagLoading: {
    name: "Loading?",
    control: "boolean",
    table: {
      category: "Feature Flags",
      subcategory: "States"
    }
  },
  featureFlagError : {
    name: "Error?",
    control: "boolean",
    table: {
      category: "Feature Flags",
      subcategory: "States"
    }
  }
});

export const StorybookFeatureFlagArgs = {
  ...defaults,
  featureFlagLoading: false,
  featureFlagError: false,
}

export default StorybookFeatureFlagsProvider;
